import "@/assets/styles/main.scss";
import "qiwa-library/src/assets/scss/_qiwa-main.scss";

import Vue from "vue";
import QiwaLibrary from "qiwa-library";
import VueMeta from "vue-meta";
import Buefy from "buefy";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "@/i18n";
import { qiwaLibraryUrls } from "@/utils/qiwa";
import { ValidationProvider, ValidationObserver } from "./validator";
import { addLogger } from "./config/logger";

Vue.config.productionTip = false;

addLogger(Vue);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueMeta);
Vue.use(Buefy);

Vue.use(QiwaLibrary, {
  urlsList: qiwaLibraryUrls,
  store,
  addQModules: false,
  layoutToInstall: ["Box"],
});

export const vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
});
