import { mapActions, mapState } from "vuex";
import { Layout as QLayout } from "qiwa-library/src/components/layout";

import { ACTION_LOCALE_SET } from "@/types/store";

export default {
  name: "Layout",
  components: {
    QLayout,
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      language: state => state.i18n.language,
    }),
  },
  methods: {
    ...mapActions({
      setLocale: ACTION_LOCALE_SET,
    }),
    async changeLang(locale) {
      await this.setLocale(locale);
    },
  },
};
