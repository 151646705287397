import axios from "axios";

import { logger } from "@/services/logger";
import { authRedirect } from "@/utils/auth";
import { environmentVariable } from "@/utils/environments";

export const validateStatus = status => {
  return (status >= 200 && status < 300) || status === 304;
};

export const identityInterceptor = res => res;

export const authInterceptor = err => {
  if (err && err.response && err.response.status === 401) {
    authRedirect();
  } else {
    return Promise.reject(err);
  }
};

export const errorInterceptor = err => {
  logger.logApiError(err);
  return Promise.reject(err);
};

export const createApiInstance = (baseUrl, responseInterceptor, options) => {
  const api = axios.create({
    baseURL: baseUrl,
    validateStatus,
    ...options,
  });

  api.interceptors.response.use(identityInterceptor, authInterceptor);
  api.interceptors.response.use(responseInterceptor, errorInterceptor);

  return api;
};

export const pgApi = createApiInstance(`${environmentVariable("VUE_APP_PG_API")}`, identityInterceptor, {
  headers: { "Auth-Token": environmentVariable("VUE_APP_AUTH_TOKEN") },
});
