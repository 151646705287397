export const ACTION_GLOBAL_LOADER_SET = "global/ACTION_GLOBAL_LOADER_SET";
export const MUTATION_GLOBAL_LOADER_SET = "global/MUTATION_GLOBAL_LOADER_SET";

export const ACTION_LOCALE_SET = "i18n/ACTION_LOCALE_SET";
export const MUTATION_LOCALE_SET = "i18n/MUTATION_LOCALE_SET";

export const ACTION_SESSION_GET = "session/ACTION_SESSION_GET";
export const ACTION_SESSION_LOGOUT = "session/ACTION_SESSION_LOGOUT";
export const MUTATION_SESSION_SET = "session/MUTATION_SESSION_SET";
export const MUTATION_SESSION_FETCH = "session/MUTATION_SESSION_FETCH";
export const MUTATION_SESSION_FETCHED = "session/MUTATION_SESSION_FETCHED";

export const ACTION_MAKE_PAYMENT_GET = "payment/ACTION_MAKE_PAYMENT_GET";
export const MUTATION_MAKE_PAYMENT_SET = "payment/MUTATION_MAKE_PAYMENT_SET";
export const MUTATION_MAKE_PAYMENT_FETCH = "payment/MUTATION_MAKE_PAYMENT_FETCH";

export const ACTION_PAYMENT_RESULT_GET = "payment/ACTION_PAYMENT_RESULT_GET";
export const MUTATION_PAYMENT_RESULT_REDIRECT_SET = "payment/MUTATION_PAYMENT_RESULT_REDIRECT_SET";
export const MUTATION_PAYMENT_RESULT_STATUS_SET = "payment/MUTATION_PAYMENT_RESULT_STATUS_SET";
export const MUTATION_PAYMENT_RESULT_FETCH = "payment/MUTATION_PAYMENT_RESULT_FETCH";

export const ACTION_PAYMENT_PRICE_GET = "payment/ACTION_PAYMENT_PRICE_GET";
export const MUTATION_PAYMENT_PRICE_SET = "payment/MUTATION_PAYMENT_PRICE_SET";
export const MUTATION_PAYMENT_PRICE_FETCH = "payment/MUTATION_PAYMENT_PRICE_FETCH";

export const ACTION_PAYMENT_STATUS_GET = "payment/ACTION_PAYMENT_STATUS_GET";
export const MUTATION_PAYMENT_STATUS_STATUS_SET = "payment/MUTATION_PAYMENT_STATUS_STATUS_SET";
export const MUTATION_PAYMENT_STATUS_FETCH = "payment/MUTATION_PAYMENT_STATUS_FETCH";

export const MUTATION_PAYMENT_TYPE_SET = "payment/MUTATION_PAYMENT_TYPE_SET";
