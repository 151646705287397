import Vue from "vue";
import Vuex from "vuex";

import i18n from "@/store/i18n";
import payment from "@/store/payment";
import paymentResult from "@/store/paymentResult";
import paymentPrice from "@/store/paymentPrice";
import paymentStatus from "@/store/paymentStatus";
import paymentType from "@/store/paymentType";
import * as types from "@/types/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  mutations: {
    [types.MUTATION_GLOBAL_LOADER_SET](state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    [types.ACTION_GLOBAL_LOADER_SET]({ commit }, payload) {
      commit(types.MUTATION_GLOBAL_LOADER_SET, payload);
    },
  },
  modules: {
    i18n,
    payment,
    paymentResult,
    paymentPrice,
    paymentStatus,
    paymentType,
  },
});
