export const ERROR_TYPES = {
  unauthorized: "UNAUTHORIZED",
  notFound: "NOT_FOUND",
  serverError: "SERVER_ERROR",
};

Object.freeze(ERROR_TYPES);

export const QIWA_STATUS = {
  loading: 0,
  success: 1,
  error: 2,
};

Object.freeze(QIWA_STATUS);

export const PROMISE_STATUS = {
  fulfilled: "fulfilled",
  rejected: "rejected",
};

Object.freeze(PROMISE_STATUS);

export const LOCALES = {
  ar: "ar",
  en: "en",
};

Object.freeze(LOCALES);

export const PAYMENT_TYPES = {
  credit: "credit",
  mada: "mada",
};
