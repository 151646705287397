import Vue from "vue";
import VueRouter from "vue-router";

import routes from "@/router/routes";
import store from "@/store";
import { loadLocaleAsync } from "@/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const lang = store.state.i18n.language;
  await loadLocaleAsync(lang);
  next();
});

export default router;
